import { LabelHTMLAttributes } from 'react';

function InputLabel({ className = '', children, ...props }: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label {...props} className={`font-semibold connect-color-primary mb-2 flex ${className}`}>
      {children}
    </label>
  );
}

export default InputLabel;
